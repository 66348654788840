import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getCreateRulePayload,
    getUpdateRulePayload,
} from '../../services/api/apiPayloads';
import {
    createRule,
    deleteRule,
    getRule,
    updateRule,
} from '../../services/api/ruleApi';
import * as C from '../../services/constants';
import { extractPoints, parseRule } from '../../services/ruleUtils';
import { normalizeString } from '../../services/typeUtils';
import { hideLoading, showLoading } from '../../store/actions/loadingActions';
import {
    notify,
    notifyError,
    notifySuccess,
} from '../../store/actions/notificationActions';
import ConditionCreator from './ConditionCreator';
import ConfirmDialog from './ConfirmDialog';
import { eventFields } from './RuleFields';

import closeIcon from '../../resources/images/close-rule-editor.svg';

const RuleEditor = ({ onClose, ruleId, status, type, origin }) => {
    const domains = Object.keys(eventFields);

    const dispatch = useDispatch();
    const { auth } = useSelector((state) => state);
    const { market, category, brand } = auth.user;
    const [domain, setDomain] = useState(domains[0]);
    const [event, setEvent] = useState({
        domain: domain,
        attributeName: 'register',
        operation: '==',
        attributeValue: 'NOT_REQUIRED',
    });
    const [conditions, setConditions] = useState([]);
    const [action, setAction] = useState({
        domain: 'CONSUMER',
        attributeName: 'points',
        operation: '==',
        attributeValue: '',
    });
    const [description, setDescription] = useState('');
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({
        display: false,
        title: '',
        message: '',
        note: '',
        onClose: null,
        onConfirm: null,
        confirmText: '',
        backText: '',
    });

    const handleDomainChange = (e) => {
        const selectedDomain = e.target.value;
        const defaultEventAttributes = eventFields[selectedDomain].reduce(
            (acc, field) => {
                if (field.type === 'dropdown') {
                    acc[field.name] = field.values[0] || '';
                } else {
                    acc[field.name] = '';
                }
                return acc;
            },
            {}
        );
        setDomain(selectedDomain);
        setEvent({
            domain: selectedDomain,
            ...defaultEventAttributes,
        });
        setConditions([]);
    };

    const handleEventChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'attributeValue': {
                if (C.REGEX_RULE_EVENT_ATTRIBUTE.test(value) || value === '') {
                    setEvent((prevEvent) => {
                        const updatedEvent = {
                            ...prevEvent,
                            attributeValue: value,
                        };
                        return updatedEvent;
                    });
                }
                break;
            }
            default: {
                setEvent((prevEvent) => {
                    const updatedEvent = { ...prevEvent, [name]: value };
                    return updatedEvent;
                });
                break;
            }
        }
    };
    const handleAddCondition = () => {
        setConditions((prevConditions) => [
            ...prevConditions,
            {
                join: '&&',
                domain: 'CONSUMER',
                attributeName: 'points',
                operation: '>=',
                attributeValue: '',
            },
        ]);
    };

    const handleDeleteCondition = (index) => {
        // if (index === 0 && conditions.length === 1) {
        //     return;
        // }
        setConditions((prevConditions) =>
            prevConditions.filter((_, i) => i !== index)
        );
    };

    const handleConditionChange = (newCondition, index) => {
        setConditions((prevConditions) => {
            const updatedConditions = [...prevConditions];
            updatedConditions[index] = newCondition;
            return updatedConditions;
        });
    };

    const handleActionChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'attributeValue': {
                if (C.REGEX_RULE_USER_POINTS.test(value) || value === '') {
                    setAction((prevAction) => ({
                        ...prevAction,
                        attributeValue: value,
                    }));
                }
                break;
            }
            default: {
                setAction((prevAction) => {
                    const updatedAction = { ...prevAction, [name]: value };
                    return updatedAction;
                });
                break;
            }
        }
    };
    const handleDescriptionChange = (e) => {
        const { value } = e.target;
        if (
            C.REGEX_RULE_DESCRIPTION.test(normalizeString(value)) ||
            value === ''
        ) {
            setDescription(value);
        }
    };
    const validateRulesData = () => {
        const stateVariables = [event, ...conditions, action, description];
        const hasNullOrUndefined = (variable) =>
            variable === null || variable === undefined;

        const hasEmptyValue = stateVariables.some((variable) => {
            if (hasNullOrUndefined(variable)) {
                return true;
            }

            if (typeof variable === 'object') {
                return Object.values(variable).some(
                    (value) => hasNullOrUndefined(value) || value.trim() === ''
                );
            } else if (typeof variable === 'string') {
                return variable.trim() === '';
            }

            return false;
        });

        return !hasEmptyValue;
    };
    const generateRule = () => {
        const booleanAttributes = [
            'register',
            'login',
            'completeProfile',
            'isReferral',
            'isGovtVerified',
            'isUpcUploaded',
        ];
        let ruleAction = `output.setPoints(${action.attributeValue.trim()});output.setoNamespace('LOYALTY_TRANSACTION')`;
        let triggerString = `(input.domain == '${event.domain.trim()}' && input.${
            event.attributeName
        } == ${
            event.domain !== 'CONSUMER'
                ? `'${event.attributeValue.trim()}'`
                : 'true'
        })`;
        let conditionString = conditions
            .map((condition) => {
                return `${condition.join} (${
                    condition.domain !== 'CONSUMER'
                        ? `input.domain == '${condition.domain.trim()}' && `
                        : ''
                }${booleanAttributes.includes(condition.attributeName.trim()) ? `input.${condition.attributeName.trim()} == true`: `input.${condition.attributeName.trim()} ${condition.operation.trim()} '${condition.attributeValue.trim()}'` })`;
            })
            .join(' ');

        let ruleCondition = `${triggerString} ${
            event.attributeName !== 'register' ? conditionString : ''
        }`.trim();

        return [ruleCondition, ruleAction];
    };

    const saveRule = () => {
        const [ruleCondition, ruleAction] = generateRule();

        if (ruleId !== null) {
            // update rule
            const updateRuleBody = getUpdateRulePayload(
                description,
                ruleCondition,
                ruleAction
            );
            dispatch(showLoading());
            updateRule(market, category, brand, ruleId, updateRuleBody)
                .then(() => {
                    onClose();
                    dispatch(
                        notify('Saved!', 'Your work has been saved in drafts.')
                    );
                })
                .catch((error) => {
                    dispatch(notifyError('Error!', 'Unable to save draft.'));
                })
                .finally(() => dispatch(hideLoading()));
        } else {
            // create rule
            const createRuleBody = getCreateRulePayload(
                description,
                ruleCondition,
                ruleAction
            );
            dispatch(showLoading());
            createRule(market, category, brand, createRuleBody)
                .then(() => {
                    onClose();
                })
                .catch((error) => {
                    dispatch(notifyError('Error!', 'Unable to save draft.'));
                })
                .finally(() => dispatch(hideLoading()));
        }
    };
    const expireRule = () => {
        const [ruleCondition, ruleAction] = generateRule();

        const expireRuleBody = getUpdateRulePayload(
            description,
            ruleCondition,
            ruleAction,
            undefined,
            C.RULE_TYPE_EXPIRED
        );
        dispatch(showLoading());
        updateRule(market, category, brand, ruleId, expireRuleBody)
            .then(() => {
                onClose();
                dispatch(
                    notifySuccess(
                        'Rule Expired!',
                        'The Consumer rule has been successfully expired.'
                    )
                );
            })
            .catch((error) => {
                dispatch(notifyError('Error!', 'Unable to expire rule.'));
            })
            .finally(() => dispatch(hideLoading()));
    };
    const removeRule = () => {
        dispatch(showLoading());
        deleteRule(market, category, brand, ruleId)
            .then(() => {
                onClose();
                dispatch(
                    notify(
                        'Deleted Draft!',
                        'Draft has been successfully deleted.'
                    )
                );
            })
            .catch((error) => {
                dispatch(notifyError('Error!', 'Unable to delete draft.'));
            })
            .finally(() => dispatch(hideLoading()));
    };
    const publishRule = () => {
        const [ruleCondition, ruleAction] = generateRule();
        if (ruleId !== null) {
            const publishRuleBody = getUpdateRulePayload(
                description,
                ruleCondition,
                ruleAction,
                undefined,
                C.RULE_TYPE_PUBLISHED
            );
            dispatch(showLoading());
            updateRule(market, category, brand, ruleId, publishRuleBody)
                .then(() => {
                    onClose();
                    dispatch(
                        notifySuccess(
                            'Published!',
                            'The rule has been successfully published.'
                        )
                    );
                })
                .catch((error) => {
                    dispatch(notifyError('Error!', 'Unable to publish rule.'));
                })
                .finally(() => dispatch(hideLoading()));
        } else {
            const createRuleBody = getCreateRulePayload(
                description,
                ruleCondition,
                ruleAction,
                undefined,
                C.RULE_TYPE_PUBLISHED,
                undefined
            );
            dispatch(showLoading());
            createRule(market, category, brand, createRuleBody)
                .then(() => {
                    dispatch(
                        notifySuccess(
                            'Published!',
                            'The rule has been successfully published.'
                        )
                    );
                    onClose();
                })
                .catch((error) => {
                    dispatch(notifyError('Error!', 'Unable to publish rule.'));
                })
                .finally(() => dispatch(hideLoading()));
        }
    };
    const closeConfirmDialog = () => {
        setConfirmDialog({ ...confirmDialog, display: false });
    };
    useEffect(() => {
        if (ruleId !== null) {
            dispatch(showLoading());
            getRule(market, category, brand, ruleId)
                .then((response) => {
                    const rule = response.data;
                    const { event, conditions } = parseRule(rule.condition);
                    setDomain((prevDomain) => {
                        return event.domain;
                    });
                    setEvent((prevEvent) => {
                        return event;
                    });
                    setConditions((prevConditions) => {
                        return conditions;
                    });

                    const points = extractPoints(rule.action);
                    const description = rule.description;
                    setAction((prevAction) => {
                        return { ...prevAction, attributeValue: points };
                    });
                    setDescription(description);
                })
                .catch((error) => {
                    dispatch(notifyError('Error!', 'Unable to fetch rules.'));
                })
                .finally(() => dispatch(hideLoading()));
        }
    }, []);
    useEffect(() => {
        const isValidData = validateRulesData();
        type === C.RULE_TYPE_EXPIRED
            ? setSaveEnabled(false)
            : setSaveEnabled(isValidData);
    }, [event, conditions, action, description]);

    return (
        <div
            className={`create-rule-container ${
                origin === C.RULE_EDITOR_MANAGE_PROGRAM &&
                'manage-rule-container'
            }`}
        >
            <div className="create-rule">
                <div className="create-rule-header">
                    <div className="create-rule-title">
                        {origin === C.RULE_EDITOR_CREATE_PROGRAM ? (
                            <>
                                <h3>Create New Rule</h3>
                                <p>* All fields are mandatory</p>
                            </>
                        ) : type === C.RULE_TYPE_EXPIRED ? (
                            <h3>View Expired Rule</h3>
                        ) : type === C.RULE_TYPE_PUBLISHED ? (
                            <h3>View Published Rule</h3>
                        ) : (
                            <h3>Add Rule</h3>
                        )}
                    </div>
                    <div className="create-rule-close">
                        <button
                            className="create-rule-close-btn"
                            onClick={onClose}
                        >
                            <img src={closeIcon} alt="close" />
                            {origin === C.RULE_EDITOR_CREATE_PROGRAM
                                ? ' Back to Rules'
                                : 'Back to Manage Rules'}
                        </button>
                    </div>
                </div>
                <fieldset className="event-container">
                    <legend>Trigger Rule WHEN</legend>
                    <div className="event">
                        <div className="event-dropdown-container">
                            <div className="rule-domain">
                                <h4>Rule Applies To: </h4>
                                <select
                                    name="domain"
                                    onChange={(e) => {
                                        handleDomainChange(e);
                                    }}
                                    value={event.domain}
                                    disabled={C.RULE_INPUT_DISABLED.includes(
                                        type
                                    )}
                                >
                                    {domains.map((domain) => (
                                        <option key={domain} value={domain}>
                                            {domain}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {eventFields[domain].map((field) => {
                                if (field.type === 'dropdown') {
                                    return (
                                        <div key={field.name}>
                                            <h4>{field.label}</h4>
                                            <select
                                                name={field.name}
                                                onChange={(e) =>
                                                    handleEventChange(e)
                                                }
                                                value={event[field.name]}
                                                disabled={C.RULE_INPUT_DISABLED.includes(
                                                    type
                                                )}
                                            >
                                                <option value="" disabled>
                                                    Select a value
                                                </option>
                                                {field.values.map((option) => (
                                                    <option
                                                        key={option}
                                                        value={option}
                                                    >
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    );
                                }
                                if (field.type === 'text') {
                                    return (
                                        <div key={field.name}>
                                            <h4>{field.label}</h4>
                                            <input
                                                name={field.name}
                                                type="text"
                                                onChange={(e) =>
                                                    handleEventChange(e)
                                                }
                                                value={event[field.name]}
                                                disabled={C.RULE_INPUT_DISABLED.includes(
                                                    type
                                                )}
                                            />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </fieldset>
                {/* {domain !== 'CONSUMER' && ( */}
                {event.attributeName !== 'register' &&
                    (conditions.length > 0 ||
                        !C.RULE_INPUT_DISABLED.includes(type)) && (
                        <fieldset className="condition-container">
                            <legend>AND the following condition is met</legend>
                            <div className="conditions">
                                <div className="condition-dropdown-container">
                                    {conditions.map((condition, index) => (
                                        <ConditionCreator
                                            key={index}
                                            ruleDomain={domain}
                                            condition={condition}
                                            onConditionChange={(newCondition) =>
                                                handleConditionChange(
                                                    newCondition,
                                                    index
                                                )
                                            }
                                            onDeleteCondition={() =>
                                                handleDeleteCondition(index)
                                            }
                                            disabled={C.RULE_INPUT_DISABLED.includes(
                                                type
                                            )}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="cta-container">
                                {conditions.length < 10 &&
                                    !C.RULE_INPUT_DISABLED.includes(type) && (
                                        <button onClick={handleAddCondition}>
                                            + Add Condition
                                        </button>
                                    )}
                            </div>
                        </fieldset>
                    )}
                <fieldset className="action-container">
                    <legend>THEN system must do the following</legend>
                    <div className="actions">
                        <div className="action-dropdown-container">
                            <div className="dropdown-container">
                                <h4>Rule Applies To:</h4>
                                <select
                                    name="domain"
                                    onChange={(e) => handleActionChange(e)}
                                    value={action.domain}
                                    disabled={C.RULE_INPUT_DISABLED.includes(
                                        type
                                    )}
                                >
                                    <option value="CONSUMER">CONSUMER</option>
                                </select>
                            </div>
                            <div className="dropdown-container">
                                <h4>Event Attribute Is:</h4>
                                <select
                                    name="attributeName"
                                    onChange={(e) => handleActionChange(e)}
                                    value={action.attributeName}
                                    disabled={C.RULE_INPUT_DISABLED.includes(
                                        type
                                    )}
                                >
                                    <option value="points">points</option>
                                </select>
                            </div>
                            <div className="operator-dropdown-container">
                                <h4>Operation:</h4>
                                <select
                                    name="operation"
                                    id=""
                                    onChange={(e) => handleActionChange(e)}
                                    value={action.operation}
                                    disabled={C.RULE_INPUT_DISABLED.includes(
                                        type
                                    )}
                                >
                                    <option value="==">==</option>
                                </select>
                            </div>
                            <div className="dropdown-container">
                                <h4>Attribute Value Is:</h4>
                                <input
                                    type="text"
                                    name="attributeValue"
                                    onChange={(e) => handleActionChange(e)}
                                    value={action.attributeValue}
                                    disabled={C.RULE_INPUT_DISABLED.includes(
                                        type
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div className="rule-description-container">
                    <div className="info-container">
                        <h4>Rule Description:</h4>
                    </div>
                    <input
                        type="text"
                        id="ruleDescription"
                        name="description"
                        onChange={(e) => handleDescriptionChange(e)}
                        value={description}
                        placeholder="If user has registered then add 100 points and assign 'Rookie' level to user"
                        disabled={C.RULE_INPUT_DISABLED.includes(type)}
                    />
                </div>
                <div className="create-rule-cta-container">
                    {origin === C.RULE_EDITOR_CREATE_PROGRAM ? (
                        <>
                            <button
                                className={`save-rule ${
                                    saveEnabled ? 'active' : 'inactive'
                                }`}
                                onClick={() => saveEnabled && saveRule()}
                            >
                                Save Rule
                            </button>
                            {ruleId && (
                                <button
                                    className={`save-rule ${
                                        saveEnabled ? 'active' : 'inactive'
                                    }`}
                                    onClick={() => saveEnabled && removeRule()}
                                >
                                    Delete Rule
                                </button>
                            )}
                        </>
                    ) : type === C.LOYALTY_PROGRAM_STATUS_PUBLISHED ? (
                        <button
                            className="expire-rule"
                            onClick={() =>
                                setConfirmDialog({
                                    display: true,
                                    title: 'Confirm: Expire Rule!',
                                    message:
                                        'Are you sure you want to expire this Consumer rule?',
                                    note: 'Once you expire this rule will no longer be triggered or be re-activated.',
                                    onConfirm: expireRule,
                                    onClose: closeConfirmDialog,
                                    confirmText: 'Yes, Expire Rule',
                                    backText: 'No, Do Not Expire',
                                })
                            }
                        >
                            Expire Rule
                        </button>
                    ) : (
                        <>
                            <button
                                className={`publish-rule ${
                                    saveEnabled ? 'active' : 'inactive'
                                }`}
                                onClick={() =>
                                    saveEnabled &&
                                    setConfirmDialog({
                                        display: true,
                                        title: 'Confirm: Publish Rule!',
                                        message:
                                            'Are you sure you want to publish this rule?',
                                        note: 'Once you publish here you can not edit or update this rule. You can add new rules or expire the rule in manage rules.',
                                        onConfirm: publishRule,
                                        onClose: closeConfirmDialog,
                                        confirmText: 'Yes, Publish Rule',
                                        backText: 'No, Continue Editing',
                                    })
                                }
                            >
                                Publish Rule
                            </button>
                            <button
                                className={`save-rule ${
                                    saveEnabled ? 'active' : 'inactive'
                                }`}
                                onClick={() =>
                                    saveEnabled &&
                                    setConfirmDialog({
                                        display: true,
                                        title: 'Confirm: Save as Draft',
                                        message:
                                            'Are you sure you want to save as draft and navigate away from this screen?',
                                        onConfirm: saveRule,
                                        onClose: closeConfirmDialog,
                                        confirmText: 'Yes, Save and Close',
                                        backText: 'No, Continue Editing',
                                    })
                                }
                            >
                                Save as Draft
                            </button>
                            <button
                                className={`delete-rule ${
                                    ruleId && saveEnabled
                                        ? 'active'
                                        : 'inactive'
                                }`}
                                onClick={() =>
                                    ruleId &&
                                    saveEnabled &&
                                    setConfirmDialog({
                                        display: true,
                                        title: 'Confirm: Delete Draft',
                                        message:
                                            'Are you sure you want to delete this draft and navigate away from this screen?',
                                        onConfirm: removeRule,
                                        onClose: closeConfirmDialog,
                                        confirmText: 'Yes, Delete Draft',
                                        backText: 'No, Continue Editing',
                                    })
                                }
                            >
                                Delete
                            </button>
                        </>
                    )}
                </div>
            </div>
            {confirmDialog.display && (
                <ConfirmDialog
                    title={confirmDialog.title}
                    message={confirmDialog.message}
                    note={confirmDialog.note}
                    onClose={confirmDialog.onClose}
                    onConfirm={confirmDialog.onConfirm}
                    confirmText={confirmDialog.confirmText}
                    backText={confirmDialog.backText}
                />
            )}
        </div>
    );
};

export default RuleEditor;
